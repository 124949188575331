import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import {Navbar, Nav} from 'react-bootstrap';

const Header = ({ siteTitle }) => (
  <Navbar collapseOnSelect expand="lg" bg="light" variant="light" fixed="top" >
  <Navbar.Brand><Link to="/" style={{ color: 'black' }}>J&R Tile Solution</Link></Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link ><Link to="/portfolio/" style={{ color: 'black' }}>Portfolio</Link></Nav.Link>
    </Nav>
    <Nav>
      <Nav.Link href="https://www.facebook.com/JR-Tile-Solutions-103769461373636" target="_blank">Find Us On Facebook!</Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
